<template>
    <v-container fluid>
      <!-- start of editor -->
      <editor :initial="record" :priceLists="priceLists" @data="save"></editor>
      <!-- end of editor -->
    </v-container>
</template>

<script>
import Editor from "./_components/editor.vue";
export default {
  components: {
    Editor
  },
  data: () => ({
    search: "",
    record: {},
    priceLists: []
  }),
  methods: {
    save(data) {
      const url = "/administration/1";
      this.$store
        .dispatch("put", { url, data })
        .then(res => {
          if (res) {
            location.reload();
          }
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getPriceLists() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/opln`)
        .then(res => {
          self.priceLists = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getData() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/administration`)
        .then(res => {
          self.record = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getPriceLists();
    this.getData();
  }
};
</script>