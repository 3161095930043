<template>
  <div>
    <v-card>
       <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Document Settings</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      
      <v-tabs>
        <v-tab dark>General</v-tab>
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.CostPrcLst"
                :items="priceLists"
                item-text="ListName"
                item-value="id"
                label="Base Price Origin"
              ></v-autocomplete>
            </v-col>

             <v-col cols="12">
              <v-btn color="accent" @click="sendData">
                <v-icon left>mdi-content-save</v-icon>Save
              </v-btn>
            </v-col>
          </v-row>

        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true
    },
    priceLists: {
      type: Array
    }
  },
  data: () => ({
    record: {}
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    sendData() {
      this.record.DfltWhs = null;
      this.record.GLMethod = null;
      this.record.DfSVatItem = null;
      this.record.DfSVatServ = null;
      this.record.DfPVatItem = null;
      this.record.DfPVatServ = null;
      const details = this.record;
      this.$emit("data", details);
    }
  }
};
</script>